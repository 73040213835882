import { Alert, Snackbar, Typography } from '@mui/material';
import React from 'react';
import Slide from '@mui/material/Slide';
import "../../../Common/css/Animation.css";

function SnackBarComponent( props ) {
let { open= true, message} = props;

function SlideTransition(props) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <div className='flex flex-col justify-center'>
    <Snackbar
      anchorOrigin={{vertical:"top", horizontal: "right"}}
      open={open}
      key={"top" + "right"}
      sx={{
        ".MuiPaper-root":{
          background : "#fff",
          p: 0,
          boxShadow: "-2px 2px 3px #00000025",
          borderRadius: "5px"
        },
      }}
      className='slide-in-right'
      children={
        <div className='bg-white rounded-lg'>
          <Typography sx={{ width: '100%', px: 2.5, py: 1.3 }} icon={false}>
          {message}
          </Typography>
          <div className="loader"></div>
        </div> 
      }
    />
  </div>
  );
}

export default SnackBarComponent;