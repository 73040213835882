import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  createTheme,
  Divider,
  ListItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatabaseService } from "../../../Services/DatabaseService";
import LeavesTable from "./components/LeavesTable";
import PlusSVG from "../../../Assets/plus.svg";
import LeaveDialog from "../AttendanceAdmin/LeaveDialog";
import FilterDatePicker from "../../shared/common/FilterDatePicker";
import dayjs from "dayjs";

const theme = createTheme();

const LeaveAdmin = ({ userData }) => {
  const [startDate , setStartDate] = useState();
  const [endDate , setEndDate] = useState();
  const [usersList, setUsersList] = useState();
  const [usersListLoading, setUsersListLoading] = useState(false);
  const [LeaveLoading, setLeaveLoading] = useState(false);
  const [leavesData, setLeavesData] = useState([]);
  const [pagination, setPagination] = useState();
  const [leavesTaken, setLeavesTaken] = useState();
  const [selectedUsers, setSelectedUsers] = useState();
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
  const [openLeaveEditDialog, setOpenLeaveEditDialog] = useState(false);
  const [HalfDayLeave, setHalfDayLeave] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    getUsersList();
  }, []);

  useEffect(() => {
    setLeavesData([]);
    setPagination();
    setLeavesData();
    getUserLeaves();
  }, [(startDate && endDate),selectedUsers]);

  const getUsersList = async () => {
    setUsersListLoading(true);
    const response = await DatabaseService("GET", "users");
    if (response.code === 200) {
      setUsersList(response.data);
    }
    setUsersListLoading(false);
  };

  const getUserLeaves = async (page = 1) => {
    setLeaveLoading(true);
    let response;
    let baseURL = "leave";
    let queryParams = [];

    if (startDate && endDate) {
        queryParams.push(`startDate=${dayjs(startDate).format('YYYY-MM-DD HH:mm')}`);
        queryParams.push(`endDate=${dayjs(endDate).add(1, 'day').format('YYYY-MM-DD HH:mm')}`);
    }

    if (selectedUsers?._id) {
        queryParams.push(`employeeId=${selectedUsers._id}`);
    }
    queryParams.push(`page=${page}`);

    if (queryParams.length > 0) {
        baseURL += '?' + queryParams.join('&');
    }

    response = await DatabaseService("GET", baseURL);

    if (response.code === 200) {
      console.log("leave_response-------------->", response);
      setLeavesData(response.data);
      setPagination(response.pagination);
      setLeavesTaken(response.leaveTypeCounts);
    }
    setLeaveLoading(false);
  };

  const handleUserSelect = (event, newValue) => {
    setSelectedUsers(newValue);
  };

  const handleEditClick = async (row, isShowing, index) => {
    console.log("row------------------>", row);
    setHalfDayLeave({
      ...row,
      halfDayLeave: row.days === "0.5" ? true : false,
      previousStatus: row.status,
      index: index,
      isAdmin: true,
    });
    setOpenLeaveEditDialog(true);
  };

  const handlePageChange = async (event, value) => {
    getUserLeaves(value);
  };

  const handleLeaveClose = async (data, updateData, id) => {
    if (!updateData) {
      setOpenLeaveDialog(false);
      setOpenLeaveEditDialog(false);
    } else {
      if (id) {
        let leaveResponse = await DatabaseService("POST", "leave/update", {
          ...data,
          id,
        });
        console.log(
          "=================leaveResponse------------>",
          leaveResponse
        );
        if (leaveResponse.code === 200) {
          getUserLeaves();
          setOpenLeaveDialog(false);
          setOpenLeaveEditDialog(false);
        } else {
          setOpenLeaveDialog(false);
          setOpenLeaveEditDialog(false);
        }
      } else {
        let leaveResponse = await DatabaseService("POST", "leave", data);
        console.log(
          "=================leaveResponse------------>",
          leaveResponse
        );
        if (leaveResponse.code === 200) {
          setOpenLeaveDialog(false);
          setOpenLeaveEditDialog(false);
          getUserLeaves();
        } else {
          setOpenLeaveDialog(false);
          setOpenLeaveEditDialog(false);
        }
      }
    }
  };

  return (
    <Box
      sx={{ mx: 1, pb: 1, pt: 1, [theme.breakpoints.down("md")]: { pt: 1 } }}
    >
      <Box className={"flex gap-2 mb-2 items-center"}>
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: 700,
            lineHeight: "27.17px",
            color: "#801F52",
            pb: 0.5,
            [theme.breakpoints.down("md")]: { textAlign: "center" },
          }}
        >
          Leaves{" "}
        </Typography>
        {/* Start date picker for Start date select  */}
        <FilterDatePicker min={'2024-01-01'} value={startDate || null} onChangeValue={(value)=>{setStartDate(value?.$d || null)}} placeholder="From Date" />
        {/* End date picker for End date select  */}
        <FilterDatePicker min={'2024-01-01'} value={endDate || null} onChangeValue={(value)=>{setEndDate(value?.$d || null)}} placeholder="To Date" />
        {/* User name list Autocomplete appeares when userList data will fetch */}
        {usersListLoading ? (
          <CircularProgress color="inherit" />
        ) : usersList && usersList.length ? (
          <Autocomplete
            id="tags-standard"
            options={usersList}
            getOptionLabel={(option) => option.displayName}
            defaultValue={selectedUsers}
            onChange={handleUserSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Search By Name"
                sx={styles.autoCompleteStyle}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Tooltip title={option.email}>
                  <Avatar
                    key={index}
                    sx={{ width: 20, height: 20, cursor: "pointer" }}
                    alt={option.displayName} // Assuming 'avatarAlt' is the property for alt text
                    src={option.imageURL} // Assuming 'avatarSrc' is the property for avatar URL
                    {...getTagProps({ index })}
                  />
                </Tooltip>
              ))
            }
          />
        ) : null}
        {usersListLoading ? null : (
          <Box className="flex grow justify-end">
            <Button
              sx={styles.addBTN}
              disabled={exportLoading}
              title="Add Leave"
              onClick={() => setOpenLeaveDialog(true)}
            >
              <img src={PlusSVG} />
            </Button>
          </Box>
        )}
      </Box>
      <Divider sx={{ borderColor: "#FBC7A4" }} />
      {selectedUsers ? (
        leavesData && leavesData.length ? (
          <Box className="flex flex-col gap-2 mt-1">
            <Box className="flex gap-2">
              {leavesTaken.map((leave, index) => {
                const leaveType = Object.keys(leave)[0]; // Extract the key (leave type)
                const leaveCount = leave[leaveType]; // Extract the value (leave count)
                return (
                  <Chip
                    key={index}
                    label={`${leaveType} : ${leaveCount}`} // Display key and value
                    sx={{
                      color: "#FF914E",
                      borderColor: "#FF914E",
                      fontWeight: "500",
                      ":hover": {
                        bgcolor: "#FF914E",
                        color: "#fff",
                      },
                    }}
                    variant="outlined"
                  />
                );
              })}
            </Box>
            {LeaveLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              <LeavesTable
                tableData={leavesData}
                pagination={pagination}
                handlePageChange={handlePageChange}
                handleEditClick={handleEditClick}
                userData={userData}
              />
            )}
          </Box>
        ) : (
          <Box>
            <Typography className="text-center">No Leaves Found.</Typography>
          </Box>
        )
      ) : leavesData && leavesData.length ? (
        <Box className="flex flex-col gap-2 mt-1">
          {LeaveLoading ? (
            <CircularProgress color="inherit" />
          ) : (
            <LeavesTable
              tableData={leavesData}
              pagination={pagination}
              handlePageChange={handlePageChange}
              handleEditClick={handleEditClick}
              userData={userData}
            />
          )}
        </Box>
      ) : (
        <Box>
          <Typography className="text-center">No Leaves Found.</Typography>
        </Box>
      )}
      {openLeaveDialog ? (
        <LeaveDialog
          openLeaveDialog={openLeaveDialog}
          handleLeaveClose={handleLeaveClose}
          data={{ usersList: usersList }}
        />
      ) : null}
      {openLeaveEditDialog ? (
        <LeaveDialog
          openLeaveDialog={openLeaveEditDialog}
          handleLeaveClose={handleLeaveClose}
          data={{ ...HalfDayLeave }}
        />
      ) : null}
    </Box>
  );
};

export default LeaveAdmin;

const styles = {
  autoCompleteStyle: {
    width: "190px",
    backgroundColor: "#fff",
    borderRadius: "15px",
    py: "2px",
    pl: "10px",
    boxShadow: "4px 4px 40px 0px #0000000D",
    ".MuiInput-input": {
      padding: "4px 4px 0px 0px",
      // marginLeft: "10px",
      fontSize: "14px",
    },
    ".MuiAutocomplete-inputRoot": {
      marginTop: "0px",
      borderBottom: "0px",
    },
    ".MuiInput-underline:before": {
      borderBottom: "0px",
    },
    ".MuiInput-underline:hover:before": {
      borderBottom: "0px",
    },
    ".MuiInput-underline:focus:before": {
      borderBottom: "0px",
    },
    ".MuiInput-underline:after": {
      borderBottom: "0px",
    },
    ".MuiInput-underline:hover:after": {
      borderBottom: "0px",
    },
    ".MuiInput-underline:focus:after": {
      borderBottom: "0px",
    },
    "& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
      borderBottom: "0px",
    },
    ".MuiAutocomplete-inputRoot:before": {
      borderBottom: "0px",
    },
    ".MuiAutocomplete-inputRoot:hover:not:before": {
      borderBottom: "0px",
    },
  },
  addBTN: {
    px: "10px",
    py: "11.5px",
    mr: "10px",
    backgroundColor: "#801F52",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "#801F52",
    },
  },
};
