import { url, version } from "../Constants/keys";


export async function DatabaseService (method, endpoint, bodyContent, id) {

    const controller = new AbortController();

    var myHeaders = new Headers();
    var token = await localStorage.getItem('userToken');
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", "Bearer "+ token );
    
    var requestOptions = {
        method: method,
        headers: myHeaders,
        redirect: 'follow',
        signal: controller.signal,
    };

    if(method === 'POST' || method === 'post' || method === "PUT" || method === "put"){
        requestOptions.body = JSON.stringify(bodyContent);
    }

    let fullUrl = url + version + endpoint;
    if(id){
        fullUrl = fullUrl + '/'+ id;
    }

    return new Promise((resolve, reject) => {
        fetch(fullUrl, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                resolve(response)
            }).catch(function (e) {
                console.log("ERR", e)
                reject(e)
            })
    });
}
