import { Box, Button, Divider, FormControl, MenuItem, Popover, Select, Typography, createTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DashboardCalender from '../../../../Assets/DashboardCalender.svg';
import workingHourPolicy from '../../../../Assets/Files/Working_Hours_Policy__Favcy_VB.pdf';
import {useNavigate } from 'react-router-dom';

const theme = createTheme();

const AvailableLeaveSection = ({ section = "leaves", handleSection, handleApplyLeave, applyLeaveFormShow = false, leavesData, handleShowHolidays ,handleShowLeaves}) => {

const [leavesBalances, setLeavesBalances] = useState({});
const navigate = useNavigate();

useEffect(()=>{
    if(leavesData){
        setLeavesBalances(leavesData);
    }
},[leavesData])

const [anchorEl, setAnchorEl] = useState(null);

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};

const open = Boolean(anchorEl);
const id = open ? 'simple-popover' : undefined;

return (
    <>
        {
            applyLeaveFormShow ? 
                <Box className={"flex items-start justify-between mr-4 gap-2 my-4 flex-wrap"}>
                    <Box sx={bodyStyle.leaveBalSection} className="flex items-center md:gap-2 flex-wrap flex-grow">
                        <Typography sx={bodyStyle.textLeaveHead} >My Leaves Monitor</Typography>
                        <Box className="flex gap-2 justify-around md:justify-start md:gap-6 flex-wrap flex-grow" sx={bodyStyle.InnerSection}>
                            <Box className="flex">
                            <span >
                                {" "}
                                <Typography sx={{...bodyStyle.LeaveFormValues, color: "#007C32"}}>
                                {" "}
                                Balance{" "}
                                </Typography>{" "}
                            </span>
                            <Typography sx={{...bodyStyle.LeaveFormValues, color: "#007C32"}}>
                                {" "}
                                :  {leavesBalances.available}{" "}
                            </Typography>
                            </Box>
                            <Box className="flex">
                            <span >
                                {" "}
                                <Typography sx={{...bodyStyle.LeaveFormValues, color: "#801F52"}}>
                                {" "}
                                Applied{" "}
                                </Typography>{" "}
                            </span>
                            <Typography sx={{...bodyStyle.LeaveFormValues, color: "#801F52"}}>
                                {" "}
                                :  {leavesBalances.booked}{" "}
                            </Typography>
                            </Box>
                            <Box className="flex">
                            <span >
                                {" "}
                                <Typography sx={{...bodyStyle.LeaveFormValues, color: "#FF3434"}}>
                                {" "}
                                Approved{" "}
                                </Typography>{" "}
                            </span>
                            <Typography sx={{...bodyStyle.LeaveFormValues, color: "#FF3434"}}>
                                {" "}
                                :  {leavesBalances.approved}{" "}
                            </Typography>
                            </Box>
                            <Box className="flex">
                            <span >
                                {" "}
                                <Typography sx={{...bodyStyle.LeaveFormValues, color: "#EE8B00"}}>
                                {" "}
                                In Drafts{" "}
                                </Typography>{" "}
                            </span>
                            <Typography sx={{...bodyStyle.LeaveFormValues, color: "#EE8B00"}}>
                                {" "}
                                :  {leavesBalances.in_draft}{" "}
                            </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Button className="flex-grow" sx={bodyStyle.commonBTN} onClick={()=>{handleShowHolidays(true)}}>View Holidays</Button>
                    <Button className="flex-grow" sx={bodyStyle.commonBTN} aria-describedby={id} onClick={handleClick}>Company Policies</Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Box className="flex p-2 py-4 gap-2">
                            <Button className="flex-grow" sx={bodyStyle.applyBTN} onClick={()=>{handleShowLeaves(true)}}>Leave Policy</Button>
                            <Button className="flex-grow" sx={bodyStyle.applyBTN} onClick={() => window.open(workingHourPolicy, '_blank')}>Working Hours Policy</Button>
                        </Box>
                    </Popover>
        
                    <Button className='flex-grow' sx={bodyStyle.commonBTN} onClick={()=>{handleApplyLeave(false);navigate("/dashboard/leaveTracker");}} >My Leaves</Button>
                </Box>
            :
            <Box className={'flex items-start justify-center md:justify-end'}>
                <Box sx={bodyStyle.leaveSection} className="px-5">
                    <Box className={"flex p-0 px-6 md:pl-2 md:pr-0"}>
                    <img src={DashboardCalender} alt="Calender Image" />
                    <Box className="flex-grow" sx={bodyStyle.leaveLeft}>
                        <Typography sx={bodyStyle.LeaveLeftHeading}>
                        Annual Paid Leaves &nbsp; Total : 15
                        </Typography>
                        <Divider
                        // variant="middle"
                        sx={{ borderColor: "#FFFFFF50", borderWidth: "1px" }}
                        />
                        <Box className="flex flex-col">
                        <Box className="flex gap-5 mr-10">
                            <Box className="flex">
                            <span >
                                {" "}
                                <Typography sx={bodyStyle.LeaveLeftValues}>
                                {" "}
                                Balance {" "}
                                </Typography>{" "}
                            </span>
                            <Typography sx={bodyStyle.LeaveLeftValues}>
                                {" "}
                                :  {leavesBalances.available}{" "}
                            </Typography>
                            </Box>
                            <Box className="flex">
                            <span >
                                {" "}
                                <Typography sx={bodyStyle.LeaveLeftValues}>
                                {" "}
                                Applied {" "}
                                </Typography>{" "}
                            </span>
                            <Typography sx={bodyStyle.LeaveLeftValues}>
                                {" "}
                                :  {leavesBalances.booked}{" "}
                            </Typography>
                            </Box>
                        </Box>
                        <Box className="flex gap-5 mr-10">
                            <Box className="flex">
                            <span >
                                {" "}
                                <Typography sx={bodyStyle.LeaveLeftValues}>
                                {" "}
                                Approved{" "}
                                </Typography>{" "}
                            </span>
                            <Typography sx={bodyStyle.LeaveLeftValues}>
                                {" "}
                                :  {leavesBalances.approved}{" "}
                            </Typography>
                            </Box>
                            <Box className="flex">
                            <span >
                                {" "}
                                <Typography sx={bodyStyle.LeaveLeftValues}>
                                {" "}
                                In Drafts{" "}
                                </Typography>{" "}
                            </span>
                            <Typography sx={bodyStyle.LeaveLeftValues}>
                                {" "}
                                :  {leavesBalances.in_draft}{" "}
                            </Typography>
                            </Box>
                        </Box>
                        </Box>
                    </Box>
                    </Box>
                </Box>
            </Box>
        }
       
    </>
)
};

export default AvailableLeaveSection;

const bodyStyle = {
    leaveSection: {
        bgcolor: "#FF914E",
        borderRadius: "15px",
        p: "4px",
        mt: -4,
        [theme.breakpoints.down("md")]: { mt: 0.5 },
      },
      leaveBalSection: {
        bgcolor: "#FEDEC9",
        borderRadius: "50px",
        pl: 2,
        pr: 0.5,
        // my: 2,
        border: "1px solid #FBC7A4",
        boxShadow: "4px 4px 40px 0px #0000000D",
        [theme.breakpoints.down("md")]: {
            borderRadius: "20px",
            pl: 0.5
        }
      },
      InnerSection:{
        bgcolor: "#FFECE4B2",
        borderRadius: "80px",
        py: 1.1,
        px: "8px",
        my: 0.5,
        ml: 2,
        [theme.breakpoints.down("md")]: {
            borderRadius: "20px",
            ml: 0
        }
      },
      textLeaveHead:{
        fontSize: '14px',
        fontWeight: 500,
        color: "#801F52",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            width: "100%",
        }
      },
      leaveLeft: {
        boxShadow: "6px 6px 40px 0px #0000000A",
        ml: "10px",
        mt: "4px",
        mb: "10px",
      },
      LeaveLeftHeading: {
        textAlign: "left",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "19.76px",
        mb: 0.4,
        color: "#FFF",
      },
      leaveRightSectionText: {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16.8px",
        maxWidth: "90%",
        color: "#FFF",
      },
      LeaveLeftValues: {
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "11.9px",
        mt: 1,
        color: "#FFF",
      },
      LeaveFormValues: {
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "17.29px",
        color: "#FFF",
      },
    applyBTN:{
        color: "#FFF",
        backgroundColor: "#801F52",
        padding: "10px 30px 10px 30px",
        borderRadius: "30px",
        lineHeight: "19.76px",
        marginRight: "10px",
        fontWeight: 700,
        "&:hover":{
            color: "#801F52",
            backgroundColor: "#FFF",
            border: "1px solid #801F52"
        }
    },
    commonBTN: {
        borderRadius: "50px",
        background: "#fff",
        color: "#801F52",
        px: "8px",
        py: 1.1,
        mb: 1,
        boxShadow: "4px 4px 40px 0px #0000000D",
        [theme.breakpoints.down("md")]: {
            px: "8px",
        }
      },
}