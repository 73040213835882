import { Autocomplete, Avatar, Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Pagination, Select, TablePagination, TextField, Tooltip, Typography, createTheme} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterDatePicker from "../../shared/common/FilterDatePicker";
import { DatabaseService } from "../../../Services/DatabaseService";
import EditSVG from '../../../Assets/EditIcon.svg';
import dayjs from "dayjs";
import RequestService from "../../../Services/RequestService";
import UserAttendanceDialog from "./UserAttendanceDialog";
import PlusSVG from "../../../Assets/plus.svg";
import LeaveDialog from "./LeaveDialog";

const theme = createTheme();

function AttendanceDashboard() {

    const [startDate , setStartDate] = useState();
    const [endDate , setEndDate] = useState();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedEmails, setSelectedEmails] = useState([]);

    const [dialogData,setDialogData] = useState({});

    const [usersList, setUsersList] = useState();
    const [loading, setLoading] = useState(true);
    const [exportLoading, setExportLoading] = useState(false);
    const [UserAttendanceData, setUserAttendanceData] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [Page, setPage] = useState(0);
    const [paginationData, setPaginationData] = useState({});
    const [dataCount, setDataCount] = useState(0);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    const [open, setOpen] = useState(false);
    const [openLeaveDialog, setOpenLeaveDialog] = useState(false);


    useEffect(()=>{
        getUsersList();
    },[]);

    useEffect(()=>{
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
    },[screenSize]);

    function getCurrentDimension(){
        return {
              width: window.innerWidth,
              height: window.innerHeight
        }
    }

    const getUsersList = async () => {
        const response = await DatabaseService('GET', "users");
        if(response.code === 200){
            setUsersList(response.data);
        }
    }

    const getAttendanceWithUser = async (nextpage = Page, rowsperPage = rowPerPage) =>{

        let baseURL = "attendance/attendance_admin";
        let queryParams = [];
    
        if (startDate && endDate) {
            queryParams.push(`startDate=${dayjs(startDate).format('YYYY-MM-DD HH:mm')}`);
            queryParams.push(`endDate=${dayjs(endDate).add(1, 'day').format('YYYY-MM-DD HH:mm')}`);
        }

        if (selectedUsers && selectedUsers.length > 0) {
            let users = selectedUsers.map(user => {
                return user.displayName
            });
            queryParams.push(`employeeNames=${users.toString()}`);
        }
        if (selectedEmails && selectedEmails.length > 0) {
            let emails = selectedEmails.map(email => {
                return email.email
            });
            queryParams.push(`userEmails=${encodeURIComponent(emails.toString())}`);
        }

        queryParams.push(`pageNumber=${encodeURIComponent(nextpage)}`);
        queryParams.push(`pageSize=${encodeURIComponent(rowsperPage)}`);
    
        if (queryParams.length > 0) {
            baseURL += '?' + queryParams.join('&');
        }

        setLoading(true);
    
        const response = await RequestService.fetchData('GET', baseURL);
        if(response.code === 200){
            setLoading(false);
            setUserAttendanceData(response.data);
            setDataCount(response?.pagination?.totalCount);
            setPaginationData(response?.pagination)
        }
    };

    useEffect(()=>{
        getAttendanceWithUser();
    },[(startDate && endDate), selectedUsers, selectedEmails])

    const handleClickOpen = async (item) => {
        setDialogData(item);
        setOpen(true);

    };
    
    const handleClose = (updateData=false) => {
        setDialogData({});
        setOpen(false);
        console.log("updateData--------------->",updateData);
        if(updateData){
            getAttendanceWithUser();
        }
    };

    const handleLeaveClose = async (data, updateData) => {
        if(!updateData){
            setOpenLeaveDialog(false);
        }else{
            let leaveResponse = await DatabaseService("POST","leave", data);
            console.log("=================leaveResponse------------>",leaveResponse);
            if(leaveResponse.code === 200){
                setOpenLeaveDialog(false);
                
            }
            
        }
    }

    const handleUserSelect = (event, newValue) => {
        setSelectedUsers(newValue);
    };

    const handleEmailSelect = (event, newValue) => {
        setSelectedEmails(newValue);
    };



    //export csv function

    const downloadCSV = async() => {

        setExportLoading(true);
        
        let baseURL = "attendance/attendance_all";
        let queryParams = [];
        
        if (startDate && endDate) {
            queryParams.push(`startDate=${dayjs(startDate).format('YYYY-MM-DD HH:mm')}`);
            queryParams.push(`endDate=${dayjs(endDate).add(1, 'day').format('YYYY-MM-DD HH:mm')}`);
        }

        if (selectedUsers && selectedUsers.length > 0) {
            let users = selectedUsers.map(user => {
                return user.displayName
            });
            queryParams.push(`employeeNames=${users.toString()}`);
        }
        if (selectedEmails && selectedEmails.length > 0) {
            let emails = selectedEmails.map(email => {
                return email.email
            });
            queryParams.push(`userEmails=${encodeURIComponent(emails.toString())}`);
        }
        
        if (queryParams.length > 0) {
            baseURL += '?' + queryParams.join('&');
        }
        
        const response = await RequestService.fetchData('GET', baseURL);
        
        let formatedData = response?.data.map((item) => ({
            "Date": dayjs(item.createdAt).format("DD MMM YYYY HH:mm"),
            'Employee Name': item?.UserDetails[0].displayName,
            'Employee Email': item?.UserDetails[0].email,
            "Check In Address": item.check_in_address ? item.check_in_address+"" : "",
            "Check In Date": item.check_in_date ? dayjs(item.check_in_date).format("DD MMM YYYY HH:mm") : "",
            "Check Out Address": item.check_out_address ? item.check_out_address+"" : "",
            "Check Out Date": item.check_out_date ? dayjs(item.check_out_date).format("DD MMM YYYY HH:mm") : "",
            "Status": item.status,
          }));
          const headers = Object.keys(formatedData[0]);
          const rows = formatedData.map(row =>
            headers.map(header => {
              let value = row[header] || '';
              if (typeof value === 'string' && value.includes(',')) {
                value = `"${value || ""}"`;
              }
              return value || "";
            }).join(',')
          ).join('\n');
        
        const csvContent = `${headers.join(',')}\n${rows}`;

        let currentTimeWithDate = dayjs().format("DD_MM_YYYY_HH_mm");

        // const encodedUri = encodeURI(csvContent);
        // const link = document.createElement('a');
        // link.setAttribute('href', encodedUri);
        // link.setAttribute('download', 'IntrahubAttendance'+currentTimeWithDate+'.csv');
        // document.body.appendChild(link); // Required for Firefox
        // link.click();

        // Open a new tab
        const newTab = window.open('', '_blank');
            
        // Write the CSV content to the new tab
        newTab.document.write('<pre>' + csvContent + '</pre>');
        
        // Create a temporary link element
        const link = newTab.document.createElement('a');
        link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
        link.download = 'IntrahubAttendance'+currentTimeWithDate+'.csv'; // Specify the file name

        // Append the link element to the new tab document body
        newTab.document.body.appendChild(link);
        
        // Simulate a click on the link element
        link.click();
        
        // Close the new tab after initiating the download
        newTab.close();

        setExportLoading(false);
      };


    const handleSelectChange = (event) => {
        setPage(event.target.value);
        getAttendanceWithUser(event.target.value);
      };

    const handleChangePage = async (event, nextPage) => {
        setPage(nextPage);
        getAttendanceWithUser(nextPage);
    }

    const handleChangeRowsPerPage = async (event) =>{
        setRowPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getAttendanceWithUser(0, event.target.value);
    }

    const customLabelDisplayedRows = () => {
        return `${paginationData.fromDocument}-${paginationData.toDocument} of ${paginationData.totalCount}`; // Custom format for displayed rows label
      };

  return (
    <div className="flex flex-1 flex-col mx-2 md:mx-5 pt-3">
        <Typography sx={styles.HeadingText}>Attendance</Typography>
        <Divider sx={styles.HeadingDivider} />
        <Box className="flex justify-between items-center my-1">
            <Box className="flex flex-wrap gap-2 items-center">
                {/* Start date picker for Start date select  */}
                <FilterDatePicker min={'2024-01-01'} value={startDate || null} onChangeValue={(value)=>{setStartDate(value?.$d || null)}} placeholder="From Date" />
                {/* End date picker for End date select  */}
                <FilterDatePicker min={'2024-01-01'} value={endDate || null} onChangeValue={(value)=>{setEndDate(value?.$d || null)}} placeholder="To Date" />
                {/* User name list Autocomplete appeares when userList data will fetch */}
                {
                    usersList && usersList.length ?
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={usersList}
                        getOptionLabel={(option) => option.displayName}
                        value={selectedUsers}
                        onChange={handleUserSelect}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Search By Name"
                            sx={styles.autoCompleteStyle}
                        />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Tooltip title={option.email} >
                                    <Avatar
                                    key={index}
                                    sx={{ width: 20, height: 20, cursor: 'pointer' }}
                                    alt={option.displayName} // Assuming 'avatarAlt' is the property for alt text
                                    src={option.imageURL} // Assuming 'avatarSrc' is the property for avatar URL
                                    {...getTagProps({ index })}
                                    />
                                </Tooltip>
                            ))
                        }
                    />
                    :
                    null
                }
                {/* User email list Autocomplete appeares when userList data will fetch */}
                {
                    usersList && usersList.length ?
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={usersList}
                        getOptionLabel={(option) => option.email}
                        value={selectedEmails}
                        onChange={handleEmailSelect}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Search By Email"
                            sx={styles.autoCompleteStyle}
                        />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Tooltip title={option.email} >
                                    <Avatar
                                    key={index}
                                    sx={{ width: 20, height: 20, cursor: 'pointer' }}
                                    alt={option.displayName} // Assuming 'avatarAlt' is the property for alt text
                                    src={option.imageURL} // Assuming 'avatarSrc' is the property for avatar URL
                                    {...getTagProps({ index })}
                                    />
                                </Tooltip>
                            ))
                        }
                    />
                    :
                    null
                }
                {
                    paginationData ? 
                    <FormControl size="small" sx={{width: '80px', background: '#fff', borderRadius: '15px', border:'none', ".MuiOutlinedInput-input":{ paddingTop: '3px', paddingBottom: '3px' },  ".MuiOutlinedInput-notchedOutline":{border: 'none'}}} variant={"outlined"}>
                        <InputLabel id="demo-select-small-label">Page</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={Page}
                            label="Page"
                            onChange={handleSelectChange}
                            >
                            {
                                Array.from({ length: paginationData.totalPages}, (_, index) => ({
                                    label: index + 1,
                                    value: index
                                  })).map((number)=>(
                                      <MenuItem value={number.value}>{number.label}</MenuItem>
                                    ))
                            }
                        </Select>
                    </FormControl>
                    :null
                }
            </Box>
            <Box>
                {/* <Button sx={styles.addBTN} disabled={exportLoading} title="Add Leave" onClick={()=>setOpenLeaveDialog(true)}>
                    <img src={PlusSVG} />                    
                </Button> */}
                <Button sx={styles.exportBTN} disabled={exportLoading} onClick={()=>downloadCSV()}>
                    {exportLoading ? 
                        <CircularProgress size={'15px'} sx={{color: '#fff'}} />
                    : 'Export' }
                    
                </Button>
            </Box>
        </Box>
        <div className='AttendanceTable' style={{width: screenSize.width<768 ? screenSize.width: 'auto', height: screenSize.width<768 ? screenSize.height-100 : screenSize.height-215,}}>
            <table className='w-full lookupTypeTable' style={{paddingRight: '2px'}}>
                <thead className='tableHeader'>
                        <th></th>
                        <th>Employee Name</th>
                        <th>Date</th>
                        <th>Day</th>
                        <th>Check In time</th>
                        <th>Check Out Time</th>
                        <th>Status</th>
                        <th>Location</th>
                        <th > </th>
                </thead>
                <tbody className='tableBody' >
                    {
                        loading ? <tr> <td className='text-center' colSpan={12}> Loading... </td> </tr> :
                        UserAttendanceData && UserAttendanceData.length ?  
                        UserAttendanceData.map((item, index)=>{
                            return <tr className='tableBodyRow py-2 shadow-sm' key={index}>
                            <td>
                                <Avatar
                                    key={index}
                                    sx={{ width: 30, height: 30, cursor: 'pointer', marginLeft: '5px' }}
                                    alt={item?.UserDetails[0]?.displayName} // Assuming 'avatarAlt' is the property for alt text
                                    src={item?.UserDetails[0]?.imageURL} 
                                />
                            </td>
                            <td><Typography
                                    paddingRight={2}
                                    borderRight={1}
                                    sx={styles.tableTextStyle}
                                    borderColor={"#94B1CB"}
                                    marginY={1}
                                    noWrap
                                    >{item?.UserDetails[0]?.displayName} </Typography> </td>
                            <td><Typography
                                    paddingRight={2}
                                    borderRight={1}
                                    sx={styles.tableTextStyle}
                                    borderColor={"#94B1CB"}
                                    marginY={1}
                                    noWrap
                                    >{dayjs(item.createdAt).format('DD-MMM-YYYY')} </Typography> </td>
                            <td><Typography
                                    paddingRight={2}
                                    borderRight={1}
                                    sx={{...styles.tableTextStyle, maxWidth: '100px'}}
                                    borderColor={"#94B1CB"}
                                    marginY={1}
                                    noWrap
                                    >{dayjs(item.createdAt).format('dddd')} </Typography> </td>
                            <td><Typography
                                    paddingRight={2}
                                    borderRight={1}
                                    sx={{...styles.tableTextStyle, maxWidth: '125px'}}
                                    borderColor={"#94B1CB"}
                                    marginY={1}
                                    noWrap
                                    >{ item.check_in_date ? dayjs(item.check_in_date).format('HH:mm') : '-' }</Typography> </td>
                            <td><Typography
                                    paddingRight={2}
                                    borderRight={1}
                                    sx={{...styles.tableTextStyle, maxWidth: '125px'}}
                                    borderColor={"#94B1CB"}
                                    marginY={1}
                                    noWrap
                                    >{ item.check_out_date ? dayjs(item.check_out_date).format('HH:mm') : '-' } </Typography> </td>
                            <td>
                                <Tooltip title={item.status} arrow={true} disableInteractive>
                                    <Typography
                                        paddingRight={2}
                                        borderRight={1}
                                        sx={{...styles.tableTextStyle, color: item.status === "Absent" ? "#FF3434" : "#0485E1"}}
                                        borderColor={"#94B1CB"}
                                        marginY={1}
                                        noWrap
                                        >{item.status} 
                                    </Typography>
                                </Tooltip>
                            </td>
                            <td><Typography
                                    paddingRight={2}
                                    borderRight={1}
                                    sx={styles.tableTextStyle}
                                    borderColor={"#94B1CB"}
                                    marginY={1}
                                    noWrap
                                    >{item.check_in_address} </Typography> </td>
                            <td className='actionCol'>
                                <div className='flex justify-end'>
                                    <button type='button' title='Edit' onClick={()=>{
                                        handleClickOpen(item);
                                        }} > <img src={EditSVG} alt="Edit Icon" /> </button>
                                </div>
                            </td>
                        </tr>
            
                        }) :
                        <tr >
                        <td colSpan={4} className='text-center pt-6 font-bold'> No Data Found </td>
                        </tr>
                    }
                    
                </tbody>
            </table>
        </div>
        {
            dataCount > 0 ? 
                <Box className="flex justify-between items-center">
                    <Pagination count={paginationData.totalPages} page={paginationData.nextPageIndex} variant="outlined" color="primary" onChange={(event, nextPage)=>{handleChangePage(event, nextPage-1)}} />
                    <TablePagination 
                        component="div"
                        count={paginationData.totalCount}
                        page={paginationData.nextPageIndex-1}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={customLabelDisplayedRows}
                    />
                </Box>
            : null
        }
        {
            open ? 
                <UserAttendanceDialog open={open} handleClose={handleClose} data={dialogData} />
            :null
        }
        {
            openLeaveDialog ? 
                <LeaveDialog openLeaveDialog={openLeaveDialog} handleLeaveClose={handleLeaveClose} data={{usersList:usersList}}/>
            :null
        }
    </div>
  );
}

export default AttendanceDashboard;

const styles = {
    HeadingText: {
        color: "#221F4B",
        typography:{
            fontWeight: 700,
            fontSize: '22px',
            lineHeight: '27.17px',
        },
        pb: '5px'
    },
    HeadingDivider: {
        borderColor: "#94B1CB",
        borderBottomWidth: "1px",
    },
    autoCompleteStyle: {
        width: "190px",
        backgroundColor: '#fff',
        borderRadius: "15px",
        py: "2px",
        pl:"10px",
        boxShadow: "4px 4px 40px 0px #0000000D",
        ".MuiInput-input":{
            padding: "4px 4px 0px 0px",
            // marginLeft: "10px",
            fontSize: '14px',
        },
        ".MuiAutocomplete-inputRoot":{
            marginTop: "0px",
            borderBottom: "0px",
        },
        ".MuiInput-underline:before":{
            borderBottom: "0px",
        },
        ".MuiInput-underline:hover:before":{
            borderBottom: "0px",
        },
        ".MuiInput-underline:focus:before":{
            borderBottom: "0px",
        },
        ".MuiInput-underline:after":{
            borderBottom: "0px",
        },
        ".MuiInput-underline:hover:after":{
            borderBottom: "0px",
        },
        ".MuiInput-underline:focus:after":{
            borderBottom: "0px",
        },
        '& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before':{
            borderBottom: "0px",
        }, 
        ".MuiAutocomplete-inputRoot:before":{
            borderBottom: "0px",
        },
        ".MuiAutocomplete-inputRoot:hover:not:before":{
            borderBottom: "0px",
        }
    },
    addBTN: {
        px: '10px',
        py: '11.5px',
        mr: "10px",
        backgroundColor: "#801F52",
        color: "#fff",
        "&:hover, &:focus":{
            backgroundColor: "#801F52",
        }
    },
    exportBTN: {
        px: '40px',
        py: '7px',
        backgroundColor: "#801F52",
        color: "#fff",
        "&:hover, &:focus":{
            backgroundColor: "#801F52",
        }
    },
    tableTextStyle: {
        typography: {
          fontSize: "13px",
          maxWidth: "131px",
          fontWeight: 500,
          [theme.breakpoints.down("md")]: { fontSize: "12px" },
        },
    },
    headerSection:{
        borderBottom: '1px solid #94B1CB',
        paddingBottom: '10px',
    },
    dialogUserName: {
        fontFamily: "Poppins, sans-serif",
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: "24.7px",
        color: '#FF914E',
    },
    dialogUserEmail: {
        fontFamily: "Poppins, sans-serif",
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: "17.29px",
        color: '#94B1CB',
    },
    dialogSelectBox: {
        ".MuiOutlinedInput-notchedOutline":{
            border: 'none',
            borderRight: '1px', 
            borderColor: '#94B1CB'
        },
        ".MuiInputBase-inputSizeSmall":{
            fontSize: '14px',
            fontWeight: 500,
            color: '#221F4B',
            paddingBottom: "0px",
            paddingTop: "0px",
            paddingLeft: "0px",
            width: '100%',
        },
        '& input': { // Targeting the input element of the Select component
            maxWidth: '100px' // Set your desired maxWidth here
        }
    },
};