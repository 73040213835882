import React, { useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import MenuList from "./Menu";
import "./Sidebar.css";
import hambarger from "../../Assets/hambarger.svg";
import { useLocation } from "react-router-dom";

export default (props) => {

  const [isOpenSideBar, setIsOpenSideBar ] = useState(false)

  function toggleMenu () {
      setIsOpenSideBar((state) => {return state == true ? false : true});
  }

  const location = useLocation();

  useEffect(() => {
    console.log(`The current route is ${location.pathname}`);
    toggleMenu();
  }, [location]);

  return (
    <Menu
      isOpen={isOpenSideBar}
      onOpen={()=>{setIsOpenSideBar(true)}}
      onClose={()=>{setIsOpenSideBar(false)}}
      customBurgerIcon={<img src={hambarger} className="customBurgerIcon" />}
    >
      <MenuList menuData={props.menuData} userProfileData={props.userProfileData} />
    </Menu>
  );
};
