import { Button, Tooltip, Typography, createTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "../Auth/CheckIN/CheckIn";
const theme = createTheme();

function LocationApp(props) {
  let { location = {}, address = {} } = props;
  // let routeAddress = address?.results?.find((address) =>
  //   address.types.includes("route")
  // );
  // let premiseAddress = address?.results?.find((address) =>
  //   address.types.includes("premise")
  // );
  
  const [finalAddress, setFinalAddress] = useState(address);

  // useEffect(() => {
  //   setTimeout(() => {
  //     getFinalAddress();
  //   }, 100);
  // }, []);

  // const getFinalAddress = async () => {
  //   if (!routeAddress) {
  //     routeAddress = await address?.results[1];
  //   }

  //   if (!premiseAddress) {
  //     premiseAddress = await address?.results[0];
  //   }

  //   let finalRoute = await routeAddress?.formatted_address?.split(",")[0].includes("+") ||
  //   routeAddress?.formatted_address?.split(",")[0]?.includes("Unnamed Road")
  //     ? routeAddress?.formatted_address?.split(",")[1]
  //     : routeAddress?.formatted_address?.split(",")[0];
    
  //   let finalPremise = await address?.results[0]?.formatted_address?.split(",")[0].includes("+") ||
  //   address?.results[0]?.formatted_address?.split(",")[0].includes("Unnamed Road")
  //     ? address?.results[0]?.formatted_address?.split(",")[1]
  //     : address?.results[0]?.formatted_address?.split(",")[0];

  //   let finalFirstIndex = await address?.results[1]?.formatted_address?.split(",")[0].includes("+") ||
  //   address?.results[1]?.formatted_address?.split(",")[0].includes("Unnamed Road") 
  //   ? address?.results[1]?.formatted_address?.split(",")[1] 
  //   : address?.results[1]?.formatted_address?.split(",")[0];


  //   if (routeAddress?.formatted_address === premiseAddress?.formatted_address) {
  //     let finalAdd = await finalFirstIndex + ", " + finalRoute;
  //     setFinalAddress(finalAdd);
  //   } else {
  //     let finalAdd = await finalPremise + ", " + finalRoute;
  //     setFinalAddress(finalAdd);
  //   }
  // }

  return (
    <Tooltip
      title={
        finalAddress
          ? finalAddress
          : null
      }
      arrow
    >
      <span>
        <Button disabled sx={{...styles.BTNStyle, width: "140px"}} >
          <marquee scrollamount="3" >
            <Typography sx={styles.BTNText}>
              {/* || /\d/.test(routeAddress?.formatted_address?.split(",")[0]) //location number check */}
              {/* {!location && !address ? <p>Loading...</p> : null}
              {location && !address ? <p>Loading...</p> : null} */}
              {!location ? <p>Loading...</p> : null}
              {/* {location ? <p>{location?.latitude} , {location?.longitude} </p> : null} */}
              {finalAddress
                ? finalAddress
                : null}
            </Typography>
          </marquee>
        </Button>
      </span>
    </Tooltip>
  );
}

export default LocationApp;

