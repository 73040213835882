import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import {DialogTitle, DialogContent, DialogActions, Button, TextField, CircularProgress, Box, Popover, MenuItem, createTheme, FormGroup, Typography, FormControlLabel, Checkbox, Tooltip, Avatar, Autocomplete, Select, FormControl, InputLabel} from '@mui/material';
import { DatabaseService } from '../../../Services/DatabaseService';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { FormStyles } from '../Leave_Tracker/Components/LeaveForm';

const theme = createTheme();

const LeaveDialog = ({openLeaveDialog, handleLeaveClose, data}) => {
    const [open, setOpen] = useState(openLeaveDialog);
    const [fetchedLeaveTypes, setFetchedLeaveTypes] = useState();
    const [selectedUsers, setSelectedUsers] = useState();
    const [isLoading, setIsloading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [permanentHolidays, setFetchedPerHolidays] = useState([]);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [formErrors, setFormErrors] = useState(false);
    const [fetchedManagerData, setFetchedManagerData] = useState([]);
    const [halfDayChecked, setHalfDayChecked] = useState(data?.halfDayLeave);
    const [formData, setFormData] = useState({
      leave_type: data.leave_type || "",
      description: data.description || "",
      from_date: new Date(dayjs(new Date( data?.from_date ? data.from_date : data?.createdAt || new Date())).hour(10).minute(0).second(0).millisecond(0)),
      to_date: new Date(dayjs(new Date( data?.to_date ? data.to_date : data?.createdAt || new Date())).hour(10).minute(0).second(0).millisecond(0)),
      status: data.LeaveDetails && data.LeaveDetails.length ? data.LeaveDetails?.[0].status : data?.status === "Revoked" ? "4" : data?.status === "Pending" ? "0" : data?.status === "Rejected" ? "2": data?.status === "In-Draft" ? "3": "1" || "1"
    });

    const openPopUp = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const spanRef = useRef(null);

    // Update the dialog open state when the prop changes
    useEffect(() => {
      console.log("data---------------------->",data);
        setOpen(openLeaveDialog);
        getLookupCodes();
    }, [openLeaveDialog]);
    
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenSize]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const handleUserSelect = (event, newValue) => {
    setSelectedUsers(newValue);
  };
    const getLookupCodes = async () => {
        setIsloading(true);
        let response = await DatabaseService("GET", "lookup/code");
        if (response && response.code === 200) {
            let completeResponseData = response.data;
            
      //filtered leaved data
      const leavesData = await completeResponseData.filter((item) => item?.lookupType?.lookup_type_name === "Leaves").sort((a, b) => {
        const nameA = a.lookup_code_name.toLowerCase();
        const nameB = b.lookup_code_name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      const permanentHolidaysData = await completeResponseData.filter((item) =>
        item.lookupType.lookup_type_name.includes("Permanent")
      );
      const optionalHolidaysData = await completeResponseData.filter((item) =>
        item.lookupType.lookup_type_name.includes("Optional")
      );
      let userName = data?.UserDetails ? data.UserDetails?.[0]?.displayName : data.userData?.[0]?.displayName;
      const LookupManager = await completeResponseData.find(
        (item) => item.lookup_code_name === userName
      );
      console.log("lookupManager---------------->",LookupManager);
      if (LookupManager) {
        let userResponse = await DatabaseService(
          "GET",
          "users/userDetailByEmail?email=" +
            LookupManager?.attribute.find(
              (obj) =>
                obj?.code_attribute_name.toLowerCase() === "manager email"
            )?.code_attribute_description
        );
        if (userResponse.code === 200) {
          setFetchedManagerData(userResponse.data);
        }else{
          setFetchedManagerData({
            displayName: "Vikrant Bahl",
            email: "vikrant.bahl@favcy.in",
            firstName: "Vikrant",
            googleId: "117779346472974669545",
            imageURL: "https://lh3.googleusercontent.com/a/ACg8ocIpZ7fbC8LewHsLpwAjHoiz50ZdtbgAx5sOBdT3MiRS=s96-c"
          });
        }
      }
            
      if (leavesData && leavesData.length) {
          let leaves = await leavesData.map((leave) => {
              return {
                  id: leave?._id,
                  type: leave?.lookup_code_name,
                  description: leave?.lookup_code_description,
                  quantum: leave?.attribute.find(
                      (attr) =>
                          attr?.code_attribute_name?.toString().trim().toLowerCase() ===
                      "quantum"
                  )?.code_attribute_description,
              };
          });
          setFetchedLeaveTypes(leaves);
          setFetchedPerHolidays(permanentHolidaysData);
      }
      setIsloading(false);
      }else{
          setIsloading(false);
      }
    };

    const handleStatusChange = async ( status, index , previousStatus ) => {
      console.log("status-------->",status, "index---------->",index, "previousStatus------------>",previousStatus);
      setFormData({...formData,status: status});
    }

    const countDays = (fromDate, toDate, holidays) => {
      const startDate = dayjs(fromDate);
      const endDate = dayjs(toDate);
      
      // Parse holidays to dayjs objects for accurate comparison
      const holidayDates = holidays.map(holiday => dayjs(holiday, 'DD-MMM-YYYY'));
      
      let sundayCount = 0;
      let holidayCount = 0;
      
      let currentDate = startDate;
  
      while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
          // Check if the current date is a Sunday
          if (currentDate.day() === 0) {
              sundayCount++;
          }
  
          // Check if the current date is a holiday
          if (holidayDates.some(holiday => currentDate.isSame(holiday, 'day'))) {
              holidayCount++;
          }
  
          currentDate = currentDate.add(1, 'day');
      }
  
      // Calculate the total number of days between fromDate and toDate inclusive
      let leaveDays = Math.floor(dayjs(toDate).diff(dayjs(fromDate), "days")) + 1;
      // Subtract the number of Sundays and holidays from the total days
      return leaveDays - sundayCount - holidayCount;
    };

    const handleDialogClose = (event , reason)=> {
      if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
        return; // Prevent closing on backdrop click or escape key press
      }
      handleLeaveClose(formData, false);
    };

    const handleInputClick = () => {
        if (spanRef.current) {
          spanRef.current.click();
        }
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handleHalfDayChange = (event) => {
      setHalfDayChecked(event.target.checked);
    }

    const handleSubmit = (event, status) => {
      event.preventDefault();
      console.log("selectedUsers---------------->",selectedUsers);
      setFormErrors(false);
      if(formData.leave_type === ""){
        setFormErrors(true);
        return
      }
      if(data.usersList  && !selectedUsers){
        setFormErrors(true);
        return
      }
      let finalData = { ...formData, email: selectedUsers? selectedUsers.email : data?.UserDetails?.[0]?.email, day: halfDayChecked? "0.5": countDays(formData.from_date, formData.to_date, permanentHolidays.map((holiday)=>{ return holiday.lookup_code_description})), manager: fetchedManagerData?.displayName || "",status: formData.status }
      handleLeaveClose(finalData, true, data._id);
    }

    const onfrom_dateChange = async (value) => {
      console.log("from_date_value", value);
      setFormData({ ...formData, from_date: dayjs(value?.$d), to_date: null });
      setHalfDayChecked(false);
      // setfrom_date(dayjs(value?.$d));
    };

    const onto_dateChange = async (value) => {
      console.log("formData----------->", formData);
      setFormData({ ...formData, to_date: dayjs(value?.$d) });
      setHalfDayChecked(false);
    };
    



    return (
        <Dialog
            open={open}
            onClose={handleDialogClose}
            maxWidth={'md'}
        >
            <DialogTitle sx={{
              fontSize: "22px",
              fontWeight: 700,
              lineHeight: "27.17px",
              color: "#801F52",
            }} >Leave Details</DialogTitle>
            <DialogContent>
                {
                    isLoading ? 
                        <CircularProgress color="inherit" />
                    :
                    <form>
                      <Box className="flex flex-col justify-between">
                        <FormGroup
                          className="flex flex-col overflow-y-auto"
                        >
                          <div className="grid md:grid-cols-5 md:gap-2 mt-1 items-start w-full">
                            <Box className="grid col-span-5 gap-1 md:gap-3">
                              <div className="grid grid-cols-10 gap-1">
                                <div className="col-span-4">
                                  <Typography sx={FormStyles.labelStyle}>
                                    Start Date*
                                  </Typography>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    {/* <DemoContainer components={["DatePicker"]}> */}
                                      <DatePicker
                                        format="DD-MMM-YYYY"
                                        sx={{
                                          "& .MuiInputBase-formControl": {
                                            border: "1px solid #FF914E",
                                            background: "#FF914E14",
                                            borderRadius: "12px",
                                            boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                                            mb: 0.5,
                                            color: "#FF914E",
                                            fontSize: "14px",
                                          },
                                          "& .MuiInputBase-formControl.Mui-disabled": {
                                            borderColor: "#FF914E66",
                                            background: "#FEDEC9",
                                            color: "#FFBB93",
                                          },
                                          "& .MuiInputBase-adornedEnd:focus": {
                                            border: "1px solid #801F52",
                                            background: "#FF914E00",
                                            borderRadius: "12px",
                                            boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                                            mb: 0.5,
                                            color: "#801F52",
                                            fontSize: "14px",
                                          },
                                          "& .MuiOutlinedInput-input": {
                                            py: "9px",
                                          },
                                          "& .MuiOutlinedInput-input.Mui-disabled": {
                                            "-webkit-text-fill-color": "#FFBB93",
                                          },
                                          "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                          },
                                          "& .MuiFormLabel-root": {
                                            top: "-7px",
                                          },
                                          "& .MuiFormLabel-root.MuiInputLabel-shrink": {
                                            transform: "translate(14px, -3px) scale(0.75)",
                                          },
                                          [theme.breakpoints.down("md")]: {
                                            "& .MuiInputBase-formControl": {
                                              width: "150px",
                                            },
                                            "& .MuiInputBase-adornedEnd:focus": {
                                              width: "150px",
                                            },
                                          }
                                        }}
                                        disabled={!data.usersList}
                                         value={
                                            formData.from_date
                                              ? dayjs(formData.from_date)
                                              : null
                                          }
                                          onChange={(value) => onfrom_dateChange(value)}
                                      />
                                    {/* </DemoContainer> */}
                                  </LocalizationProvider>
                                </div>
                                <div className="col-span-4">
                                  <Typography sx={FormStyles.labelStyle}>End Date*</Typography>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    {/* <DemoContainer components={["DatePicker"]}> */}
                                      <DatePicker
                                        format="DD-MMM-YYYY"
                                        sx={{
                                          "& .MuiInputBase-formControl": {
                                            border: "1px solid #FF914E",
                                            background: "#FF914E14",
                                            borderRadius: "12px",
                                            boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                                            mb: 0.5,
                                            color: "#FF914E",
                                            fontSize: "14px",
                                            width: screenSize.width/5.5,
                                          },
                                          "& .MuiInputBase-formControl.Mui-disabled": {
                                            borderColor: "#FF914E66",
                                            background: "#FEDEC9",
                                            color: "#FFBB93",
                                          },
                                          "& .MuiInputBase-adornedEnd:focus": {
                                            border: "1px solid #801F52",
                                            background: "#FF914E00",
                                            borderRadius: "12px",
                                            boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                                            mb: 0.5,
                                            color: "#801F52",
                                            fontSize: "14px",
                                            width: screenSize.width/5.5,
                                          },
                                          "& .MuiOutlinedInput-input": {
                                            py: "9px",
                                          },
                                          "& .MuiOutlinedInput-input.Mui-disabled": {
                                            "-webkit-text-fill-color": "#FFBB93",
                                          },
                                          "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                          },
                                          "& .MuiFormLabel-root": {
                                            top: "-7px",
                                          },
                                          "& .MuiFormLabel-root.MuiInputLabel-shrink": {
                                            transform: "translate(14px, -3px) scale(0.75)",
                                          },
                                          [theme.breakpoints.down("md")]: {
                                            "& .MuiInputBase-formControl": {
                                              width: "150px",
                                            },
                                            "& .MuiInputBase-adornedEnd:focus": {
                                              width: "150px",
                                            },
                                          }
                                        }}
                                        disabled={!data.usersList}
                                        minDate={
                                          formData.from_date && dayjs(formData.from_date)
                                        }
                                        value={
                                          formData.to_date ? dayjs(formData.to_date) : null
                                        }
                                        onChange={(value) => {
                                          onto_dateChange(value);
                                        }}
                                      />
                                    {/* </DemoContainer> */}
                                  </LocalizationProvider>
                                </div>
                                <div className="col-span-1 flex flex-col relative">
                                  <Typography sx={FormStyles.labelStyle}>Days*</Typography>
                                  <Typography
                                    sx={{
                                      px: 2,
                                      fontSize: "14px",
                                      border: "1px solid",
                                      py: 1,
                                      borderRadius: "12px",
                                      color:  "#FFBB93",
                                      borderColor:  "#FF914E66" ,
                                      background: "#FEDEC9",
                                    }}
                                  >
                                    { halfDayChecked ? "0.5" : countDays(formData.from_date, formData.to_date, permanentHolidays.map((holiday)=>{ return holiday.lookup_code_description})) || 0 }
                                  </Typography>
                                  {
                                    formData.leave_type !== "Work From Home" && formData.to_date && countDays(formData.from_date, formData.to_date, permanentHolidays.map((holiday)=>{ return holiday.lookup_code_description})) === 1 ? 
                                      <FormGroup className="absolute bottom-[-30%] w-[100px]">
                                        <FormControlLabel 
                                          sx={{
                                            m: 0,
                                            mr: 1,
                                            ".MuiFormControlLabel-label":{
                                              fontSize: "14px",
                                              color: "#FF914E",
                                            }
                                          }}
                                        control={<Checkbox
                                        checked={halfDayChecked}
                                        onChange={handleHalfDayChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                          sx={{
                                            padding: "0px 1px",
                                            pr: "4px",
                                            color: "#FF914E",
                                            '&.Mui-checked': {
                                              color: "#FF914E",
                                            },
                                            '.MuiSvgIcon-fontSizeMedium':{
                                              fontSize: "18px"
                                            }
                                          }}
                                        />} 
                                        label="Half Day"
                                        />
                                      </FormGroup>
                                    :null
                                  }
                                  
                                </div>
                              </div>
                              <Box className={'flex flex-col gap-3 w-3/5'}>
                                  {
                                    data?.usersList && data?.usersList.length ?
                                    <Box className={""}>
                                    <Typography sx={FormStyles.labelStyle}>Select Employee*</Typography>
                                    <Autocomplete
                                        id="tags-standard"
                                        options={data.usersList?.filter((obj)=> {return obj.status === "active"}).sort((a, b) => {
                                          if (a.displayName < b.displayName) {
                                              return -1;
                                          }
                                          if (a.displayName > b.displayName) {
                                              return 1;
                                          }
                                          return 0;
                                      })}
                                        getOptionLabel={(option) => option.displayName}
                                        value={selectedUsers}
                                        onChange={handleUserSelect}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Search By Name"
                                            sx={styles.autoCompleteStyle}
                                        />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Tooltip title={option.email} >
                                                    <Avatar
                                                    key={index}
                                                    sx={{ width: 20, height: 20, cursor: 'pointer' }}
                                                    alt={option.displayName} // Assuming 'avatarAlt' is the property for alt text
                                                    src={option.imageURL} // Assuming 'avatarSrc' is the property for avatar URL
                                                    {...getTagProps({ index })}
                                                    />
                                                </Tooltip>
                                            ))
                                          }
                                        />
                                      </Box>
                                        :
                                        null
                                  }
                              </Box>
                              <Box className={'flex flex-col gap-3 w-3/5'}>
                                  {
                                    !data?.usersList ?
                                      <Box>
                                        <Typography sx={FormStyles.labelStyle}>
                                          Approval manager*
                                        </Typography>
                                        <Box
                                          className=""
                                          sx={{
                                            px: 2,
                                            fontSize: "14px",
                                            border: "1px solid",
                                            py: 1,
                                            borderRadius: "12px",
                                            mt: 1,
                                            color:  "#FFBB93",
                                            borderColor:  "#FF914E66" ,
                                            background: "#FEDEC9",
                                            [theme.breakpoints.down("md")]: {
                                              minWidth: '150px',
                                            },
                                          }}
                                        >
                                          <input
                                            type="text"
                                            readOnly
                                            id={"input-label"}
                                            className=" block mr-2 text-sm outline-none bg-transparent"
                                            style={FormStyles.inputBox}
                                            value={
                                              fetchedManagerData.displayName || ""
                                            }
                                          />
                                        </Box>
                                      </Box>
                                    :null
                                  }
                                <Box className={""}>
                                  <Typography sx={FormStyles.labelStyle}>Leave Type*</Typography>
                                  <Box
                                    className="flex"
                                    sx={{
                                      px: 2,
                                      fontSize: "14px",
                                      border: "1px solid",
                                      py: 1,
                                      borderRadius: "12px",
                                      mt: 1,
                                      color:  "#FFBB93",
                                      borderColor:  "#FF914E66" ,
                                      background: "#FEDEC9",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      readOnly
                                      id={"input-label"}
                                      className="block mr-2 text-sm outline-none flex-grow bg-transparent"
                                      style={FormStyles.inputBox}
                                      value={formData.leave_type || ""}
                                      onClick={handleInputClick}
                                    />
                                      <span onClick={handleClick} ref={spanRef}>
                                        <KeyboardArrowDownIcon />
                                      </span>
                                    <Popover
                                      id={id}
                                      open={openPopUp}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                      sx={{
                                        "& .MuiPopover-paper": {
                                          background: "#FEDEC9",
                                          border: "1px solid #801F52",
                                          borderRadius: "10px",
                                        },
                                      }}
                                    >
                                      {fetchedLeaveTypes && fetchedLeaveTypes.length
                                        ? fetchedLeaveTypes.map((leave) => {
                                            return (
                                              <MenuItem
                                                sx={{
                                                  fontSize: "14px",
                                                  color: "#801F52",
                                                  ":hover": {
                                                    bgcolor: "#FFE8DC",
                                                    color: "#FF914E",
                                                    borderRadius: "10px",
                                                  },
                                                }}
                                                onClick={() => {
                                                  setFormData({
                                                    ...formData,
                                                    leave_type: leave.type,
                                                  });
                                                  setHalfDayChecked(false);
                                                  handleClose();
                                                }}
                                                key={leave?._id}
                                              >
                                                {leave.type}
                                              </MenuItem>
                                            );
                                          })
                                        : null}
                                    </Popover>
                                  </Box>
                                </Box>
                              </Box>
                              <Box className="">
                                <Typography sx={FormStyles.labelStyle}>
                                  Comment (Optional)
                                </Typography>
                                <Box>
                                  <textarea
                                    className="w-full rounded-[12px] bg-[#FF914E14] border-[#FF914E] border mt-2 text-[#FF914E] placeholder:text-[#ffc7a7] focus:border-[#801F52] focus:text-[#801F52] disabled:border-[#FF914E66] disabled:bg-[#FEDEC9] disabled:text-[#FFBB93]"
                                    placeholder="Please enter your reason for leave.."
                                    rows={2}
                                    value={formData.description}
                                    onInput={(e) => {
                                      setFormData({
                                        ...formData,
                                        description: e.target.value,
                                      });
                                    }}
                                    style={{
                                      padding: "15px",
                                      outline: "none",
                                      fontSize: "14px",
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                              {
                                data.isAdmin? 
                                    <Box>
                                    <Typography sx={FormStyles.labelStyle}>Status*</Typography>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={formData.status}
                                            onChange={(e)=>{ handleStatusChange(e.target.value, data.index, data.status); }}
                                            sx={{
                                              px: 2,
                                              fontSize: "16px",
                                              border: "0px",
                                              outline: "none",
                                              boxShadow: "0px",
                                              color:  "#FFBB99",
                                              background: "#FEDEC9",
                                              minWidth: "200px",
                                              borderRadius: "14px",
                                              [theme.breakpoints.down("md")]: {
                                                minWidth: '150px',
                                              },
                                            }}
                                        >
                                            <MenuItem value={"0"}>Pending</MenuItem>
                                            <MenuItem value={"1"}>Approved</MenuItem>
                                            <MenuItem value={"2"}>Rejected</MenuItem>
                                            <MenuItem value={"3"}>In-Draft</MenuItem>
                                            <MenuItem value={"4"}>Revoked</MenuItem>
                                        </Select>
                                    </Box>
                                :null
                              }
                            <Box className="grid col-span-5">
                              {formErrors ? (
                                <Typography className="p-0 text-red-500 align-middle text-center w-full">
                                  Please fill all * fields.
                                </Typography>
                              ) : null}
                            </Box>
                          </div>
                        </FormGroup>
                        <div className="flex justify-end items-center gap-4">
                            <Button
                              sx={FormStyles.cancelBTN}
                              onClick={handleDialogClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              sx={FormStyles.applyBTN}
                              type="submit"
                              onClick={(e) => {
                                handleSubmit(e, 0);
                              }}
                            >
                              Submit
                            </Button>
                        </div>
                      </Box>
                    </form>
                }
            </DialogContent>
        </Dialog>
    );
};

const styles = {
  autoCompleteStyle: {
    borderRadius: "15px",
    py: "3px",
    px: 2,
    fontSize: "14px",
    border: "1px solid",
    borderRadius: "12px",
    mt: 1,
    color:  "#FFBB93",
    borderColor:  "#FF914E66" ,
    background: "#FEDEC9",
    boxShadow: "4px 4px 40px 0px #0000000D",
    ".MuiInput-input":{
      padding: "4px 4px 0px 0px",
      color:  "#FFBB93",
        // marginLeft: "10px",
        fontSize: '14px',
    },
    ".MuiAutocomplete-inputRoot":{
        marginTop: "0px",
        borderBottom: "0px",
    },
    ".MuiInput-underline:before":{
        borderBottom: "0px",
    },
    ".MuiInput-underline:hover:before":{
        borderBottom: "0px",
    },
    ".MuiInput-underline:focus:before":{
        borderBottom: "0px",
    },
    ".MuiInput-underline:after":{
        borderBottom: "0px",
    },
    ".MuiInput-underline:hover:after":{
        borderBottom: "0px",
    },
    ".MuiInput-underline:focus:after":{
        borderBottom: "0px",
    },
    '& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before':{
        borderBottom: "0px",
    }, 
    ".MuiAutocomplete-inputRoot:before":{
        borderBottom: "0px",
    },
    ".MuiAutocomplete-inputRoot:hover:not:before":{
        borderBottom: "0px",
    }
},
};

export default LeaveDialog;
